.home-container{
    padding: 20px;
    height: 100vh;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 10%;
    padding-right: 10%;
    min-height: 100vh;
}

.home-profile{
    height: 100%;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 15px lightgray;
    display: inline-flex;
    min-height: 20rem;
    max-height: 40rem;
}


.home-profile-description{
    background-color: #ededed;
    width: 100%;
    height: 100%;
    font-family: Poppins,sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #bbb;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: .5px;
    margin-bottom: 41px;
}

.home-profile-description > * {
    background-color: #ededed;
    transition: font-size 0.5s;
    color: rgb(100 100 100);
    letter-spacing: 4px;
}

.home-profile-description > *:hover {
    font-size: 35px;
}

.home-profile-socials{
    margin: 0.5rem;
}

.home-profile-socials> *{
    font-size: 2rem;
    margin: 0.2rem;
}

.home-profile-socials> a > svg{
    background-color: transparent!important;
}

.home-profile-photo-container{
    width: 500px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0;
    transition: transform 2s ease;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.home-profile-photo{
    width: 100%;
    position: relative;
    transform: scale(1.5); 
    transition: transform 0.75s ease;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
}

.home-profile-photo:hover {
    transform: scale(1.75); 
}

.jobTitle{
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
     -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
         animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


/*  Mobile   */
@media only screen and (max-width: 960px) {
    .home-profile{
        display: flex;
        flex-direction: column;
    }

    .home-profile-description{
        margin-bottom: 0px;
        height: 50%;
    }

    .home-profile-photo{
        width: 100%;
    }

    .home-profile-photo-container{
        width: 100%;
    }
}